<template>
  <el-dialog  title="临时服务人员"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="1000px"
              @closed="closeModal">
    <div style="width: 100%;">
      <ch-button style="margin-bottom: 10px;" @click="openAddTag">新增</ch-button>
      <ch-table :render-option="tableOption" :data="tableList"
                :loading="isTableLoading"
                :props="{width: '100%', height: '300px', size: 'mini'}">
        <template #handle="{ row }">
          <ch-button type="delete" v-if="row.status === 2" @click="toExamine(row)">审核</ch-button>
          <ch-button type="link" v-if="row.status===0" @click="handleRecoverStop(row)">停用</ch-button>
          <ch-button type="link" v-if="row.status===1" @click="handleRecoverStop(row)">恢复</ch-button>
          <ch-button type="link" @click="openEdit(row)">编辑</ch-button>
        </template>
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-page
          type="old"
          ref="page"
          :getListFunction="getList"></ch-page>
    </div>
    <modal ref="tagsModal" :getListFunction="getList"></modal>
    <el-dialog  title="审核"
                :close-on-click-modal="false"
                :visible.sync="isOpenExamineModal"
                append-to-body
                width="500px"
                @closed="closeExamineModal">
      <ch-form
          :render-option="examineOption"
          :model="examineForm"
          ref="examineFormRef" style="width: 100%;"
          :rules="rulesExamine"
          :props="{ labelWidth: '120px', paddingRight: '10px' }"
      />
      <div slot="footer" class="flex_con">
        <ch-button type="cancel" @click="closeExamineModal">取消</ch-button>
        <ch-button type="ok" :loading="isExamineLoading" @click="handleExamine">确定</ch-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
const modal = () => import("./modal.vue");
export default {
  components: {
    modal
  },
  data(){
    return {
      isOpenModal:false,
      isTableLoading:false,
      tableList:[],
      isOpenExamineModal:false,
      examineForm:{
        status: 0,
      },
      rulesExamine: {
        status: [{ required: true, message: "请选择状态", trigger: "change" },],
      },
      id: null,
      isExamineLoading: false,
      // 状态(0:正常，1:停用,2:待审核,3:不通过）
      statusList: ['正常','停用','待审核','不通过'],
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "姓名", prop: "name", showOverflowTooltip: true,minWidth: '100px'},
        { column: "text", label: "手机号", prop: "phone", showOverflowTooltip: true,minWidth: '100px'},
        { column: "text", label: "绑定社区", prop: "propertyCommunityName", showOverflowTooltip: true,minWidth: '100px'},
        { column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,minWidth: '100px'},
        { column: "slot", label: "操作", prop: "", width: "120px", slotName: "handle",fixed: "right" },
      ];
    },
    examineOption: function () {
      return [
        {type: "radio", label: '审核状态', prop: "status", option: [
            {label: "通过", value: 0},
            {label: "不通过", value: 1},
          ]}
      ]
    },
  },
  methods:{
    open(){
      this.isOpenModal = true
      this.getList()
    },
    getList(){
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/serviceStaff/getCommunityList").then((res) => {
        this.tableList = res.rows.map(item => {
          return {
            ...item,
            _status: this.statusList[item.status]
          }
        })
      }).finally(() => {this.isTableLoading = false});
    },
    openAddTag(){
      this.$refs?.tagsModal?.openAdd()
    },
    openEdit(row){
      this.$refs?.tagsModal?.openEdit(row)
    },
    // 恢复停用
    handleRecoverStop(row) {
      let text = row.status===0? '停服':'恢复'
      this.$confirm(`${text}本服务人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let status = row.status===0? 1:0
        this.$curl.post(`/hm/serviceStaff/updateStatus/${row.id}/${status}`)
            .then(() => {
              this.$message.success("操作成功！");
              this.getList()
            })
      })
    },
    closeModal(){
      this.isOpenModal = false
    },
    //审核
    toExamine(row) {
      this.isOpenExamineModal = true
      this.id = row.id
      this.$refs.examineFormRef.clearValidate()
    },
    closeExamineModal(){
      this.isOpenExamineModal = false
      this.examineForm = {
        status: 0,
      }
      this.id = null
    },
    handleExamine(){
      this.isExamineLoading = true
      this.$curl.get(`/hm/serviceStaff/communityAudit/${this.id}/${this.examineForm.status}`).then(() => {
        this.$message.success("审核通过！");
        this.getList()
        setTimeout(() => {
          this.closeExamineModal()
        })
      }).finally(() => {
        this.isExamineLoading = false
      })
    },
  }
}
</script>
<style scoped lang="scss">
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.circle_green{
  background: #00ff00;
}
.circle_red{
  background: #ff0000;
}
</style>